/**
*   @name: fonts.scss
*   @description: Estilos de fuente y tipografía
*/

/*    Font families    */

@font-face {
    font-family: 'Sen';
    src: url('../fonts/Sen/Sen-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

h1, label {
    font-family: Sen;
}
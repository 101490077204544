

#home {
    background-color: white;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    color: $primary;

    .container{
        background-color: $primary;
        width: 100%;
        color: white;
        height: 100px;
    }
    .container2{
        width: 92%;
        height: 83vh;
        width: 90%;
        display: flex;
        font-size: large;
    }
    
    #mypic {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    #fileBtn {
        padding: 29px;
        font-size: 1.4rem;
        border-radius: 1500rem;
        color: white;
        background-color: $primary;
        border: solid 2px $primary;
        display: inline-block;
    }

    .canvas-img {
        width: 500px;
        height: auto;
    }
}
#foto {
    background-color: white;
    width: 100%; 
    height: 100vh;
 
    .container{
        width: 100%;
        align-content: center;
        justify-content: center;
        .column{
            width: 80%;
        }
    }

    #mypic {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        align-content: center;
    }

    #fileBtn {
        padding: 10px;
        font-size: 1em;
        border-radius: 50rem;
        color: white;
        background-color: $primary;
        display: inline-block;
    }
        
    .canvas-img{
        width:100%;
        height: auto;
        align-content: center;
    }
    
}